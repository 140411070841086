import Swalert from "sweetalert2";
import iconSuccess from '@/assets/icones/icon-success.svg'
import iconError from '@/assets/icones/icon-error.svg'

const erros = {
  500: "Sem conexão com o servidor",
};

const objetos = {
  methods: {
    //tratamento de erro
    alertErro(e, errorMessage) {

      let mensagem;

      let responseErrorMessage = e?.response?.data?.message || '';

      if (responseErrorMessage) {
        if (typeof responseErrorMessage == "object") {
          mensagem = Object.values(responseErrorMessage).join(",");
        } else {
          mensagem = responseErrorMessage;
        }
      } else if (errorMessage) {
        mensagem = errorMessage;
      } else {
        mensagem = erros?.[e?.response?.status] || "Sem conexão com o servidor";
      }

      Swalert.fire({
        icon: "error",
        title: mensagem,
      });
    },

    //tratamento de erro
    alertErroList(title, e) {
      title = title == '' ? 'Ops, encontramos alguns problemas' : title;

      // texto padrão
      let mensagem = erros?.[e?.response?.status] || "Sem conexão com o servidor";
      let responseErrorMessage = e?.response?.data?.message || '';

      if (responseErrorMessage) {
        // trecho de código será executado apenas se existir mensagem retornada da API

        if (typeof responseErrorMessage == "object") {
          // é um OBJETO, uma lista será exibida para o usuário
          // estrutura do back:
          // [campo] => [ texto_validacao ]
          mensagem = '<ul class="sweetalert-error--list">';
          for (let item of Object.values(responseErrorMessage)) {
            mensagem += `<li>${item}</li>`;
          }
          mensagem += '<ul>';

        } else {
          // se não for um OBJETTO é atribuído a string
          mensagem = responseErrorMessage;
        }
      }

      Swalert.fire({
        imageUrl: iconError,
        imageWidth: "200px",
        title: title,
        html: mensagem,
      });
    },

    //alert de confirmação
    async alertAnswer(message = "Tem certeza que deseja realizar o procedimento?", text = "", icone = "warning") {
      return Swalert.fire({
        icon: icone,
        title: message,
        html: text,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      });
    },

    //alert de alerta
    async alertWarning(message = "Verifique as informações", descricao = "") {
      return Swalert.fire({
        icon: "warning",
        title: message,
        text: descricao,
      });
    },

    async alertSuccess(message = "Procedimento realizado com sucesso!", text = "") {
      return Swalert.fire({
        icon: "success",
        title: message,
        html: text,
      });
    },

    async alertSuccessImage(message = "Procedimento realizado com sucesso!", text = "") {
      return Swalert.fire({
        imageUrl: iconSuccess,
        imageWidth: "200px",
        title: message,
        html: text,
      });
    },
  },
};

export default objetos;
